<template>
  <section>
    <BoxContainerLoading :loading="!loading" />
    <b-tabs
      content-class="mt-2"
      justified
      v-if="loading"
    >

      <b-col sm="12">
        <b-row>
          <b-col sm="9"></b-col>
          <b-form-group class="col-sm-3">
            <h4>เลือก เดือนปี</h4>
            <v-select
              v-model="form.select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="options"
            />
          </b-form-group>
        </b-row>
      </b-col>

      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>แผงควบคุม</span>
        </template>
        <Index />
      </b-tab>
      <b-tab
        title="ข้อมูลการขาย"
        v-if="_isPartnerOrAdmin"
      >
        <PartnersSaleData ref="PartnersSaleData" />
      </b-tab>
      <b-tab
        title="ข้อมูลการเติมเครดิต"
        v-if="_isClient"
      >
        <IndexPurchaseCreditTable
          v-if="!reloadQuery"
          ref='IndexPurchaseCreditTable'
          :url="`/api/admin/userTransactionData?filter=creditPurchase&user_id=${form.userId}${_dateStartEnd}`"
        />
      </b-tab>
      <b-tab
        title="ข้อมูลการซื้อรูป"
        v-if="_isClient"
      >
        <IndexPurchaseImageTable
          v-if="!reloadQuery"
          ref='IndexPurchaseImageTable'
          :url="`/api/admin/userTransactionData?filter=purchaseImage&user_id=${form.userId}${_dateStartEnd}`"
        />
      </b-tab>
      <b-tab
        title="รูปที่ซื้อ ทั้งหมด"
        v-if="_isClient"
      >
        <b-container
          fluid
          class="p-1 bg-dark"
        >
          <PartnerDashboardGallery
            ref='PartnerDashboardGallery'
            :userId='userId'
          />
        </b-container>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import IndexPurchaseCreditTable from '../../../../apps/admins/UserTransactionData/Components/index/IndexPurchaseCreditTable.vue'
import IndexPurchaseImageTable from '../../../../apps/admins/UserTransactionData/Components/index/IndexPurchaseImageTable.vue'
import PartnerDashboardGallery from './Dashboards/PartnerDashboardGallery.vue'
import PartnersSaleData from './Dashboards/PartnersSaleData.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
export default {
  data() {
    return {
      userId: '',
    }
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading', 'options', 'form', 'reloadQuery']),
    ...mapGetters('dashboardDataStore', ['_isPartnerOrAdmin', '_isPartner', '_isAdmin', '_isClient']),

    _dateStartEnd() {
      const dateStart = this.$date(this.form.select).format('YYYY-MM-DD')
      const dateEnd = this.$date(this.form.select).endOf('month').format('YYYY-MM-DD')
      return `&dateStart=${dateStart}&dateEnd=${dateEnd}`
    },
  },
  components: {
    Index: () => import('./Dashboards'),
    BCardText,
    BTabs,
    BTab,
    IndexPurchaseCreditTable,
    IndexPurchaseImageTable,
    PartnerDashboardGallery,
    PartnersSaleData,
    vSelect,
  },
  methods: {
    ...mapMutations('dashboardDataStore', ['CLEAR_DATA']),
  },
  mounted() {
    this.CLEAR_DATA()
    setTimeout(() => {
      this.userId = this.$route.params.userId
    }, 5)
  },
}
</script>
