<template>
  <div>
    <!-- component -->
    <viewer
      :images="images"
      v-if="!reloadQuery"
    >
      <img
        v-for="src in images"
        :key="src"
        :src="src"
        class="card-image-custom mt-2 mr-2"
      >
    </viewer>
    <!-- api -->

  </div>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import { mapState } from 'vuex'
Vue.use(VueViewer)
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      images: [],
    }
  },
  watch: {
    reloadQuery() {
      this.query()
    },
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading', 'options', 'form', 'reloadQuery']),
    _dateStartEnd() {
      const dateStart = this.$date(this.form.select).format('YYYY-MM-DD')
      const dateEnd = this.$date(this.form.select).endOf('month').format('YYYY-MM-DD')
      return `dateStart=${dateStart}&dateEnd=${dateEnd}`
    },
  },
  created() {
    this.query()
  },
  methods: {
    show() {
      this.$viewerApi({
        images: this.images,
      })
    },
    async query() {
      this.images = await this.api.get(
        `/api/customers/image-transaction/transactionPurchaseImage/${this.userId}?${this._dateStartEnd}`,
      )
    },
  },
}
</script>



<style lang="scss" scoped>
.card-image-custom {
  height: 300px;
  object-fit: scale-down;
  background-color: #47526f;
}

.card .card-footer {
  padding: 1rem;
}
.custom-size-btn-icon {
  padding: 0.2rem;
  margin-left: 0.5rem;
  svg {
    width: 12px;
  }
}

.cs-group-status-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
}
</style>
