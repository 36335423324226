<template>
  <b-row>
    <b-col sm="12">
      <TableDataWidget
        :ref="`tableUserInfoCreditPurchase`"
        @clickNew="$refs[`indexFormModalCreditPurchase`].show()"
        @clickView="$refs[`indexFormModalCreditPurchase`].show($event)"
        @clickEdit="$refs[`indexFormModalCreditPurchase`].show({...$event})"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/admin/userTransactionData',$event)"
        :columns="_columns"
        :newAble="false"
        viewAble
        :editAble="false"
        :deleteAble="false"
        :items="results['topicImageTotalSaleTable']"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'PartnersSaleData',
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading']),
    ...mapGetters('dashboardDataStore', ['_isAdmin']),
    _columns() {
      let columns = [
        {
          label: 'เลขอ้างอิง',
          field: 'id',
        },
        {
          label: 'ชื่อรูป',
          field: 'topic_name',
        },
        {
          label: 'เครดิต เดิม',
          field: 'credit_balance',
          type: 'number',
          tdClass: 'text-right',
        },
        {
          label: 'เครดิต เพิ่ม',
          field: 'credit_amount',
          type: 'number',
          tdClass: 'text-right',
        },
        {
          label: 'เครดิต รวม',
          field: 'credit_total',
          type: 'number',
          tdClass: 'text-right',
        },
      ]
      if (!this._isAdmin) {
        columns.push({
          label: 'หัก ( % )',
          field: 'revenue_sharing',
          thClass: 'text-center',
          tdClass: 'text-right',
        })
      }

      columns.push({
        label: 'วันที่',
        field: row => this.$date(row.created_at).format('YYYY-M-D HH:mm:ss'),
        tdClass: 'text-center',
        thClass: 'text-center',
      })
      return columns
    },
  },
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
